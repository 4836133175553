<script>
import {list, formalizar} from '@/domain/leiloes/services/filaVendas'
import {date, UTooltip} from 'uloc-vue'
import {datePtToEn} from '@/utils/date'
import {ErpInput, ErpSField, ErpSelect} from 'uloc-vue-plugin-erp'
import DefaultMixin from '../mixin'
import DateInput from "@/reuse/input/Date"
import {listName, listStorage} from "../config/list"
import ListMixin from "@/reuse/list/ListMixin"
import GlobalPersonMixin from "@/components/pessoa/globalPersonMixin"
import windowBem from '@/components/bem/windows/bem'
import windowArrematante from '@/components/arrematante/windows/arrematante'
import windowLote from '@/components/leiloes/windows/lote'
import SlCheckbox from "@/reuse/input/Checkbox"
import {addDays, addMonths, endOfMonth, endOfWeek, format, startOfMonth, startOfWeek, subDays} from "date-fns"
import BtnEmissoesLoteFila from "components/leilao-venda-fila-espera/components/buttons/Emissao"
import EmailCobrancaView from "components/leiloes/components/cobranca/EmailCobrancaView"
import EmailFormalizacaoView from "components/leiloes/components/cobranca/EmailFormalizacaoView"

const SEGUNDOS_PARA_RELOAD = 30

const filters = {
  id: null,
  active: true,
  deleted: false,
  search: null,
  lote: null,
  data1: format(new Date(), 'dd/MM/yyyy'),
  data2: format(new Date(), 'dd/MM/yyyy'),
  periodo: 'hoje',
  status: [0, 1, 2],
  statusTipo: 'formalizar'
}

export default {
  name: 'FilaVendasLeiloes',
  mixins: [DefaultMixin, ListMixin, GlobalPersonMixin],
  components: {
    EmailFormalizacaoView,
    EmailCobrancaView,
    BtnEmissoesLoteFila,
    SlCheckbox,
    DateInput,
    ErpInput,
    ErpSField,
    ErpSelect,
    UTooltip
  },
  data() {
    const filtroPeriodos = [
      {
        id: 'tudo', type: 'tudo',
        label: 'Tudo',
        value: 'tudo', active: false, action: function () {
          this.filters.data1 = null
          this.filters.data2 = null
        }
      },
      {
        id: 'hoje', type: 'day',
        label: 'Hoje',
        value: 'hoje', active: false, action: function () {
          this.filters.data1 = format(new Date(), 'dd/MM/yyyy')
          this.filters.data2 = format(new Date(), 'dd/MM/yyyy')
        }
      },
      {
        id: 'esta-semana', type: 'week',
        label: 'Esta semana',
        value: 'semana', active: false, action: function () {
          const start = addDays(startOfWeek(new Date()), 0);
          const end = subDays(endOfWeek(new Date()), 0);
          this.filters.data1 = format(start, 'dd/MM/yyyy')
          this.filters.data2 = format(end, 'dd/MM/yyyy')
        }
      },
      {
        id: 'este-mes', type: 'month',
        label: 'Este mês',
        value: 'mes', active: true, action: function () {
          const end = endOfMonth(new Date());
          this.filters.data1 = format(startOfMonth(new Date()), 'dd/MM/yyyy')
          this.filters.data2 = format(end, 'dd/MM/yyyy')
        }
      }
    ]
    const _filters = JSON.parse(JSON.stringify(filters))
    return {
      listName: listName,
      listType: 'a',
      filters: _filters,
      tipoPeriodo: filtroPeriodos,
      loading: false,
      secondsRemainingForReloading: SEGUNDOS_PARA_RELOAD
    }
  },
  watch: {
    'filters.status' (v) {
      this.$nextTick(() => {
        this.pesquisar()
      })
    },
    'filters.periodo' (v) {
      this.$nextTick(() => {
        console.log(v)
        const f = this.tipoPeriodo.find(t => t.value === v)
        f && f.action && f.action.call(this)
        this.$nextTick(() => {
          this.load()
        })
      })
    }
  },
  computed: {
  },
  beforeCreate() {
    this.listStorage = listStorage
  },
  mounted() {
    this.load()
  },
  beforeDestroy () {
    this.clearReloadInterval()
  },
  destroyed() {
    // document.querySelector('.u-erp-layout-container').classList.remove('bg-white')
  },
  methods: {
    initCountdownToReload () {
      this.secondsRemainingForReloading = SEGUNDOS_PARA_RELOAD
      this.intervalId = setInterval(() => {
        if (this.secondsRemainingForReloading === 0) {
          this.load()
        } else {
          --this.secondsRemainingForReloading
        }
      }, 1000)
    },
    clearReloadInterval () {
      if (null !== this.intervalId) clearInterval(this.intervalId)
    },
    request({pagination, filter}) {

      // this.table.serverPagination.rowsNumber = 0

      let data1, data2
      let extraFilters = []

      if (this.filters.data1) {
        if (this.filters.data1.length < 10) {
          alert('Digite a data inicial e data final corretamente para o filtro de entrada.')
          return
        }

        data1 = datePtToEn(this.filters.data1)
        data2 = this.filters.data2 ? datePtToEn(this.filters.data2) : data1
        if (!date.isValid(data1) || !date.isValid(data2)) {
          alert('Digite a data inicial e data final corretamente. Formato: dd/mm/yyyy')
          return
        }
        extraFilters.push(`&data1=${data1}&data2=${data2}`)
      }

      this.filters.tipoData && extraFilters.push(`&typeDate=${this.filters.tipoData}`)

      this.filters.id && extraFilters.push(`&id=${this.filters.id}`)
      this.filters.search && extraFilters.push(`&search=${this.filters.search}`)
      this.filters.lote && extraFilters.push(`&lote=${this.filters.lote}`)

      if (Array.isArray(this.filters.status) && this.filters.status.length > 0) {
        extraFilters.push(`&status=${this.filters.status.join(',')}`)
      }

      // this.filters.pessoa && extraFilters.push(`&pessoa=${this.filters.pessoa.id || this.filters.pessoa}`)
      this.filters.statusTipo !== null && extraFilters.push(`&statusTipo=${this.filters.statusTipo}`)

      this.table.loading = true
      let _filter = this.filtros
      let filtros = encodeURI(`&sortBy=${pagination.sortBy || 'createdAt'}&descending=${pagination.sortBy ? (pagination.descending ? 'true' : 'false') : 'false'}&${extraFilters.join('')}`)

      list(pagination.rowsPerPage, pagination.page, filtros)
          .then(({data}) => {
            this.table.serverPagination = pagination
            this.table.serverPagination.rowsNumber = data.total
            this.table.serverData = data.result
            this.stats = data.stats
            if (data.counts) {
              this.counts = data.counts
            }

            this.table.loading = false
            this.clearReloadInterval()
            this.initCountdownToReload()
          })
          .catch(error => {
            this.alertApiError(error)
            this.loading = false
          })
    },
    novo(t = null) {
      console.log('New...')
      this.windowVistoria(null)
    },
    pesquisar() {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.filters.descricao || this.table.busca
      })
    },
    windowBem: windowBem,
    windowArrematante: windowArrematante,
    windowLote: windowLote,
    /*abrir(id) {
      this.$router.push({name: 'propostas-venda-direta.show', params: {id: id}})
    },
    edit(id) {
      this.$router.push({name: 'propostas-venda-direta.show', params: {id: id}})
    },
    excluir(id) {
      this.confirmarExclusao('Tem certeza que deseja excluir este registro? Todos os dados serão perdidos, sem possibilidade de recuperá-los.', 'excluir ' + id)
          .then(() => {
            this.$uloc.loading.show()
            deleteProposta(id)
                .then(() => {
                  this.$uloc.loading.hide()
                  this.$uloc.notify({
                    color: 'positive',
                    message: `Conta excluída com sucesso.`
                  })
                  this.load()
                })
                .catch(error => {
                  this.$uloc.loading.hide()
                  this.alertApiError(error)
                })
          })
          .catch(() => {
          })
    },*/
    label(a, b) {
      return a
    },
    confirmarFormalizacao (venda) {
      this.$uloc.dialog({
        title: 'Confirmar Formalização',
        message: `Você tem certeza que deseja confirmar a formalização do lote ${venda.lote.numeroString || venda.lote.numero}?`,
        ok: 'Sim',
        cancel: 'Não',
        color: 'green'
      }).then(() => {
        formalizar(venda.id)
            .then(response => {
              venda.formalizacao = true
            })
            .catch(error => {
              this.alertApiError(error)
            })
      }).catch(() => {

      })
    },
    formalizacaoEnviada (lote, formalizadoPor) {
      console.log('Enviada!', lote)
      const arremate = this.table.serverData.find(a => a.lote.id === lote.id)
      if (arremate) {
        arremate.formalizacao = true
        arremate.formalizadoPor = formalizadoPor
      }
    }
  },
  meta: {
    title: 'Fila de Vendas',
    name: 'Fila'
  }
}
</script>

<template>
  <div class="vistoria-container wrapper-md">
    <e-row class="m-b-md">
      <e-col style="max-width: 350px">
        <h5 class="">Fila de Espera de Vendas</h5>
        <div>
          <span class="font-11">Recarregando em {{secondsRemainingForReloading}}</span> <u-btn @click="load" icon="refresh" round rounded flat size="xs" :loading="loading" />
        </div>
        <!--<resumo v-if="!isEmbedComponent" :stats="stats" :isEmbedComponent="isEmbedComponent" />-->
      </e-col>
      <!--        <e-col class="flex items-start justify-end col-grow-1">
                <div class="saldo-contas font-12 font-avenir text-grey-7 flex column m-l-xxl">
                  <div class="at-title m-b">PERÍODO</div>
                  <ul class="menu-alfabeto bold flex column" style="margin-top: 0; margin-left: -8px">
                    <li v-for="filtro in filtroPeriodos" :key="filtro.value"><a @click="buscaPorPeriodo(filtro)" :class="{active: filtro.active}">{{ filtro.name || filtro.value }}</a></li>
                  </ul>
                </div>
              </e-col>-->
      <e-col class="flex items-center justify-center col-grow-1">
        <!--          <ul class="menu-alfabeto bold m-l-xxl">
                    <li v-for="filtro in filtroPeriodos" :key="filtro.value"><a @click="buscaPorPeriodo(filtro)" :class="{active: filtro.active}">{{ filtro.name || filtro.value }}</a></li>
                  </ul>-->
        <!-- <fin-filtros-adicionais class="m-r" :filters="filters" :stats="stats" />
        <filtro-conta :stats="stats" />
        <filtro-periodo :stats="stats" @mudarPeriodo="buscaPorPeriodo" @addsub="addsubPeriodo" :periodos="filtroPeriodos" :filters="filters" class="m-l-lg" />-->
      </e-col>
      <e-col class="justify-end flex" style="max-width: 200px">
        <!--<fin-saldo-contas @new="(t) => novo(t)" :type="listType" :stats="stats" :isEmbedComponent="isEmbedComponent" />-->
        <!--          <u-btn @click="windowVistoria" no-caps color="green" class="app-btn font-12 no-border-radius-right">Registrar vistoria</u-btn>-->
        <!-- @TODO -->
      </e-col>
    </e-row>
    <div class="erp-list list-tasks no-pad">
      <div class="erp-list-top-btns items-end">
<!--        <div class="actives-btns col-grow-1">
          <u-btn @click="changeListType('t')" :label="label('Todas', 't')" class="active-table-btn"
                 :class="{active: listType === 't'}" no-caps/>
          <u-btn @click="changeListType('a')" :label="label('Ativas', 'a')" class="active-table-btn"
                 :class="{active: listType === 'a'}" no-caps/>
          <u-btn @click="changeListType('f')" :label="label('Finalizadas', 'f')" class="active-table-btn"
                 :class="{active: listType === 'f'}" no-caps/>
        </div>-->
        <div class="end font-14 bg-white flex-zero flex no-wrap">
          <e-col style="min-width: 170px" class="m-l m-r">
            <erp-s-field
                view="ll"
                label="Buscar"
            >
              <erp-input shortkey="F2" v-model="filters.search" @keydown.enter="pesquisar"/>
            </erp-s-field>
          </e-col>
          <e-col style="min-width: 170px" class="m-l m-r">
            <erp-s-field
                view="ll"
                label="Buscar Lote"
            >
              <erp-input shortkey="F3" v-model="filters.lote" @keydown.enter="pesquisar" class="bg-grey-3"/>
            </erp-s-field>
          </e-col>
          <e-col style="min-width: 150px" class="m-r">
            <date-input @keydown.enter.native="pesquisar" field-view="ll" label="Data (De)"
                        v-model="filters.data1"/>
          </e-col>
          <e-col style="min-width: 150px" class="m-r">
            <date-input @keydown.enter.native="pesquisar" field-view="ll" label="Data (Até)"
                        v-model="filters.data2"/>
          </e-col>
          <e-col style="min-width: 150px" class="m-r">
            <erp-select v-model="filters.periodo" placeholder="Período" :options="tipoPeriodo" />
          </e-col>
          <e-col style="min-width: 150px" class="m-r">
            <erp-select v-model="filters.statusTipo" placeholder="Qualquer" :options="[
                {label: 'Para Formalizar', value: 'formalizar'},
                {label: 'Para Receber', value: 'pagar'},
                {label: 'Tudo', value: 'tudo'},
            ]" />
          </e-col>
          <e-col style="min-width: 50px" >
            <u-btn @click="load" icon="refresh" flat size="sm" />
          </e-col>
          <!--              <e-col style="min-width: 150px">
                          <erp-s-field
                              view="ll"
                              label="Filtro Data"
                          >
                            <erp-select
                                :options="[
                                    {label: 'Criação', value: 'createdAt'},
                                    {label: 'Início', value: 'startTime'},
                                    {label: 'Prazo', value: 'deadline'},
                                ]"
                                v-model="filters.tipoData" />
                          </erp-s-field>
                        </e-col>
                        <e-col style="min-width: 150px">
                          <status-task-select field-view="ll" multiple v-model="filters.status" @change="load()" code-as-value />
                        </e-col>-->

        </div>
      </div>
      <u-table
          ref="table"
          color="primary"
          :data="table.serverData"
          :columns="table.columns"
          :visible-columns="visibledColumns"
          :filter="table.busca"
          row-key="id"
          :pagination.sync="table.serverPagination"
          @request="request"
          selection="multiple"
          :selected.sync="table.selected"
          :loading="table.loading"
          class="fila-table"
          :rows-per-page-options="[20, 50, 100]"
          :hide-no-results-label="true"
      >
        <u-tr slot="header" slot-scope="props">
<!--          <u-th auto-width>
            <u-checkbox
                v-model="props.selected"
                :indeterminate="props.partialSelected"
                size="sm"
            />
          </u-th>-->
          <u-th v-for="col in props.cols" :key="col.name" :props="props">
            <template>
              {{ col.label }}
            </template>
            <div v-if="col.name === 'options'" class="text-center">
              <u-btn style="margin: -10px 0 -6px" @click="listOptions" label="Configurar lista" rounded round flat
                     no-caps size="xs" icon="cog" icon-type="fa" color="grey-4" class="text-grey-8"/>
            </div>
          </u-th>
        </u-tr>

        <u-tr slot="body" slot-scope="props"
              :props="props" style="cursor: default">
<!--          <u-td auto-width>
            <u-checkbox color="primary" v-model="props.selected" size="xs"/>
          </u-td>-->
          <u-td key="id" :props="props">
            {{ props.row.id }}
          </u-td>
          <u-td key="data" :props="props" class="">
            <span v-if="props.row.data">{{ props.row.data|formatDate('dd/MM/yyyy HH:mm:ss') }}</span>
          </u-td>
          <u-td key="leilao" class="td-limit l-200" :props="props">
            <span v-if="props.row.lote" class="reverse">
              <a @click="gotoLeilao(props.row.lote.leilao)" class="text-grey-8">{{ props.row.lote.leilao.titulo }}</a>
              <u-tooltip content-class="bg-indigo" :offset="[5, 5]">{{ props.row.lote.leilao.titulo }}</u-tooltip>
            </span>
          </u-td>
          <u-td key="lote" :props="props">
            <span v-if="props.row.lote">
              <a @click="windowLote(props.row.lote)" class="text-grey-8">{{ props.row.lote.numeroString || props.row.lote.numero }}</a>
            </span>
          </u-td>
          <u-td key="bem" :props="props" class="td-limit l-200">
            <span v-if="props.row.lote && props.row.lote.bem">
              <a @click="windowBem(props.row.lote.bem.id)" class="text-grey-8">{{ props.row.lote.bem.siteTitulo }}</a>
              <u-tooltip content-class="bg-indigo" :offset="[5, 5]">{{ props.row.lote.bem.siteTitulo }}</u-tooltip>
            </span>
          </u-td>
          <u-td key="arrematante" :props="props" class="">
            <span v-if="props.row.comprador">
              <a @click="windowArrematante(props.row.comprador.id)" class="text-grey-8">{{ props.row.comprador.pessoa.name }}</a>
            </span>
          </u-td>
          <u-td key="valor" :props="props" class="">
            <span v-if="props.row.lance">R$ {{ props.row.lance.valor|moeda }}</span>
          </u-td>
          <u-td key="tipoLeilao" :props="props" class="">
            <span v-if="props.row.leilaoInstancia">{{props.row.leilaoInstancia}}º leilão</span>
          </u-td>
          <u-td key="formalizadoPor" :props="props" class="">
            {{props.row.formalizadoPor}}
          </u-td>
          <u-td key="formalizado" :props="props">
            <sl-checkbox v-model="props.row.formalizacao" @click.prevent.native="confirmarFormalizacao(props.row)" />
          </u-td>
          <u-td key="status" :props="props">
            <u-btn @click="windowLote(props.row.lote, 'window=formalizacao')" icon="folder-open" icon-type="fa" color="blue-grey-4" size="sm" flat dense rounded>
              <u-tooltip :offset="[5,5]">Formalização</u-tooltip>
            </u-btn>
            <u-btn @click="$refs.formalizacaoView.load(props.row.lote.id)" icon="handshake" icon-type="fa" color="blue-grey-4" size="sm" flat dense rounded>
              <u-tooltip :offset="[5,5]">Formalizar</u-tooltip>
            </u-btn>
            <u-btn @click="$refs.cobrancaView.load(props.row.lote.id)" icon="envelope" icon-type="fa" color="blue-grey-4" size="sm" flat dense rounded class="m-l-xs">
              <u-tooltip :offset="[5,5]">Cobrar</u-tooltip>
            </u-btn>
            <u-btn @click="windowLote(props.row.lote, 'window=pagamento')" icon="dollar-sign" icon-type="fa" color="blue-grey-4" size="sm" flat dense rounded class="m-l-xs">
              <u-tooltip :offset="[5,5]">Pagamento</u-tooltip>
            </u-btn>
            <btn-emissoes-lote-fila class="m-l-xs" :lote="props.row.lote" />
          </u-td>
          <u-td class="text-center" key="options" :props="props">
<!--            <e-btn-table-options>
              <menu-options>
                <list-menu @abrir="abrir(props.row.id)" @edit="edit(props.row.id)"
                                @excluir="excluir(props.row.id)" :leilao="props.row"/>
              </menu-options>
            </e-btn-table-options>-->
          </u-td>
        </u-tr>
      </u-table>

      <!--<div class="flex justify-between">
        <div class="m-t">
          <u-btn icon="print" color="blue-grey-6" flat round no-caps>
            <u-tooltip :offset="[5,5]">Imprimir</u-tooltip>
          </u-btn>
          <u-btn icon="file_upload" color="blue-grey-6" flat round no-caps>
            <u-tooltip :offset="[5,5]">Exportar</u-tooltip>
          </u-btn>
        </div>
        <div>

        </div>
      </div>-->
    </div>
    <email-cobranca-view ref="cobrancaView" />
    <email-formalizacao-view ref="formalizacaoView" @formalizacaoEnviada="formalizacaoEnviada"></email-formalizacao-view>
  </div>
</template>
